<template>
  <div>
    <p>
      {{ dialogData.Description }}
    </p>
    <div v-for="(field, key) in fields" :key="key">
      <dialog-form-field
        v-model="fields[key]"
        :field="fields[key]"
        :key="key"
      />
    </div>
  </div>
</template>

<script>
import DialogFormField from "@/components/form/FormField";
export default {
  name: "DialogForm",
  components: {DialogFormField},
  props: {
    dialogData: {
      type: Object,
      default: () => ({}),
    },
  },
  provide() {
    return {
      translations: global.session.translations,
    };
  },
  data() {
    return {
      fields: this.dialogData.Fields,
    };
  },
  computed: {
    actions() {
      return this.dialogData?.GlobalActions;
    },
  },
};
</script>
